<template>
  <div class="act-user-cnt" v-html="cntHtml"></div>
</template>

<script>
import { requestInterval, randomNum } from '@/utils/tools';
export default {
  name: '',
  data() {
    return {
      cntHtml: '',
      initCnt: 384362,
      timer: null,
    };
  },
  methods: {
    getCntHtml() {
      this.cntHtml =
        '已有' +
        this.initCnt
          .toString()
          .split('')
          .map((item) => `<span>${item}</span>`)
          .join('') +
        '人使用';
    },
  },
  created() {
    // this.initCnt = randomNum(300000, 400000);
    this.getCntHtml();
    this.timer = requestInterval(() => {
      const randNum = randomNum(0, 30);
      this.initCnt += randNum;
      // localStorage.setItem('initActUserCnt', this.initCnt);
      this.getCntHtml();
    }, 2000);
  },
  beforeDestroy() {
    this.timer && cancelAnimationFrame(this.timer);
  },
};
</script>

<style scoped lang="scss">
.act-user-cnt::v-deep {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  span {
    display: block;
    width: 0.3rem;
    height: 0.3rem;
    background-color: #fff;
    color: #000;
    text-align: center;
    line-height: 0.3rem;
    font-size: 0.22rem;
    border-radius: 0.05rem;
    margin: 0 0.03rem;
    & + span {
      margin-left: 0.03rem;
    }
  }
}
</style>
