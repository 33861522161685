import Vue from 'vue';
import Vuex from 'vuex';

import gdydStore from './modules/gdyd';
import order from './modules/order';
import config from './modules/config';
import ad from './modules/ad';
import gdyd from './modules/gdyd';
import permission from './modules/permission';
import hunan_cmcc from './modules/hunan_cmcc';
import byte_mobile from './modules/byte_mobile';

import getters from './getters';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    gdydStore,
    order,
    config,
    ad,
    gdyd,
    permission,
    hunan_cmcc,
    byte_mobile,
  },
  getters,
});
