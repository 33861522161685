import { getBytesToken } from '@/utils/byteGetPhone';
import { getBytePhoneApi } from '@/api';
import { Toast } from 'vant';
import { dotHANDLE } from '@/api/dot';
const getDefaultState = () => {
  return {
    byteFormVisible: false,
    createFormByte: false,
    byteDialogVisible: false,
    createDialogByte: false,
    byteMobile: '',

    // // model 弹窗类型，embed 嵌入类型
    byteTypeModel: [1209, 1236],
    byteTypeEmbed: [1235, 1230, 1417, 1461, 1449, 1442],
  };
};
const state = getDefaultState();

const mutations = {
  SET_BYTE_FORM_VISIBLE: (state, byteFormVisible) => {
    state.byteFormVisible = byteFormVisible;
  },
  SET_CREATE_FORM_BYTE: (state, createFormByte) => {
    state.createFormByte = createFormByte;
  },

  SET_BYTE_DIALOG_VISIBLE: (state, byteDialogVisible) => {
    state.byteDialogVisible = byteDialogVisible;
  },
  SET_CREATE_DIALOG_BYTE: (state, createDialogByte) => {
    state.createDialogByte = createDialogByte;
  },

  SET_BYTE_MOBILE: (state, byteMobile) => {
    state.byteMobile = byteMobile;
  },
};

const actions = {
  getBytesTokenHandle({ commit, state, rootGetters }) {
    if (process.env.NODE_ENV === 'development') return;
    const { byteTypeModel, byteTypeEmbed } = state;
    const { params } = rootGetters;
    const { actId, chl, channel } = params || {};
    if (!byteTypeModel.includes(actId) && !byteTypeEmbed.includes(actId)) return;

    Toast.loading({ overlay: true, message: '加载中...' });
    if (byteTypeModel.includes(actId)) {
      commit('SET_CREATE_DIALOG_BYTE', true);
    }
    if (byteTypeEmbed.includes(actId)) {
      commit('SET_CREATE_FORM_BYTE', true);
    }

    getBytesToken({
      onSuccess: async function (response) {
        console.log(response);
        dotHANDLE({
          action: 'BYTE',
          act: actId,
          actionIntro: `一键认证-getBytesToken-success`,
          queryKey: 'successCode',
          queryValue: response?.code,
          queryKeyNd: 'successMessage',
          queryValueNd: response?.message,
          reqParams: JSON.stringify({ actId, chl, channel }),
          respParams: JSON.stringify(response),
        });
        Toast.clear();
        // alert(response);
        // alert(JSON.stringify(response));
        if (response?.code === '103000') {
          if (response?.message === '弹窗加载成功') {
            // 弹窗加载成功
            // _this.bytePhoneDialog = true;
            if (byteTypeModel.includes(actId)) {
              commit('SET_BYTE_DIALOG_VISIBLE', true);
            }
            if (byteTypeEmbed.includes(actId)) {
              commit('SET_BYTE_FORM_VISIBLE', true);
            }
          } else if (response?.token) {
            // setPopupVisible(false);
            // _this.bytePhoneDialog = false;
            commit('SET_BYTE_DIALOG_VISIBLE', false);
            commit('SET_BYTE_FORM_VISIBLE', false);

            commit('SET_CREATE_DIALOG_BYTE', false);
            commit('SET_CREATE_FORM_BYTE', false);
            console.log({ token: response?.token, userInformation: response?.userInformation });
            // 请求后端，换取真实的手机号
            const res = await getBytePhoneApi({
              token: response?.token,
              userInformation: response?.userInformation,
            });

            dotHANDLE({
              action: 'BYTE',
              act: actId,
              actionIntro: `一键认证-getBytePhoneApi`,
              queryKey: 'mobile',
              queryValue: res?.data?.mobile || '',
              reqParams: JSON.stringify({
                token: response?.token,
                userInformation: response?.userInformation,
              }),
              respParams: JSON.stringify(res),
            });
            console.log(res);
            // alert(JSON.stringify(res));
            if (res.data.mobile) {
              // _this.byteMobile = res.data.mobile;
              commit('SET_BYTE_MOBILE', res.data.mobile);
            }
          }
        }
      },
      onError: function (err) {
        console.log('byteMobileError :>>', err);
        dotHANDLE({
          action: 'BYTE',
          act: actId,
          actionIntro: `一键认证-getBytesToken-error`,
          queryKey: 'errorCode',
          queryValue: err?.code,
          queryKeyNd: 'errorMessage',
          queryValueNd: err?.message,
          reqParams: JSON.stringify(params),
          respParams: JSON.stringify(err),
        });
        Toast.clear();
        // alert(JSON.stringify(err));
        if (err?.message === '用户点击控件') {
          // 更换号码，退出取号，隐藏取号弹窗
          commit('SET_BYTE_DIALOG_VISIBLE', false);
          commit('SET_BYTE_FORM_VISIBLE', false);
          commit('SET_CREATE_DIALOG_BYTE', false);
          commit('SET_CREATE_FORM_BYTE', false);
        }
      },
      returnStyleConfig: function () {
        // console.log(styleConfig);
      },
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
