<template>
  <div id="app" ref="app">
    <router-view ref="routerView" />

    <!-- 渠道码 -->
    <template v-if="!hasActId([999, 697, 1343])">
      <footer id="bottom-code" v-if="hasActId([595, 648])">
        <img
          src="https://sgact.oss-cn-hangzhou.aliyuncs.com/actbb2/static/img/yidong_xy2_henan_tg_bottom.png"
          alt=""
        />
      </footer>
      <footer id="bottom-code" v-else-if="hasActId([486, 476, 488])">
        <img
          src="https://sgact.oss-cn-hangzhou.aliyuncs.com/actbb2/static/img/liantong_486_tx_xy_bottom.png"
          alt=""
        />
      </footer>
      <footer id="bottom-code" v-else-if="hasActId([653])">
        <img
          src="https://sgact.oss-cn-hangzhou.aliyuncs.com/actbb2/static/img/cmcc_tx_mc_author.png"
          alt=""
        />
      </footer>
      <footer id="bottom-code" v-else-if="codeUrl">
        <!-- 渠道码权益超市 -->
        <img :src="codeUrl" alt="" />
      </footer>
    </template>

    <Beian v-if="hasActId([1378])" :host="host" />

    <!--客服logo-->
    <a
      v-if="(productId == 202 || productId == 274) && $route.path.includes('index')"
      class="tel-icon"
      href="tel:4008089391"
    >
      <img src="https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/590/tel_icon_590.png"
    /></a>

    <!-- 悬浮按钮 -->
    <fixed-btn />

    <!-- 尾流弹窗 -->
    <Ad />

    <!-- 二次确认弹窗 -->
    <SubmitDialog />

    <!-- <SubmitContentDialog /> -->

    <!-- 订购须知弹窗 -->
    <RuleDialog />

    <CqFanZha />
  </div>
</template>

<script>
import { getQueryParams, createScript } from '@/utils/tools';
import { mapGetters } from 'vuex';
import FixedBtn from '@/components/Btn/fixedBtn.vue';
import CqFanZha from '@/components/CqFanZha/index.vue';
export default {
  components: { FixedBtn, CqFanZha },
  data() {
    return {
      query: {},
      host: '',
    };
  },
  computed: {
    ...mapGetters([
      'params',
      'unAdWlChannel',
      'preOrderData',
      'ipProductInfo',
      'curProdJson',
      'productId',
    ]),
    formInfo: function () {
      return this.curProdJson.formInfo || {};
    },
    codeUrl() {
      return this.preOrderData?.codeUrl || this.ipProductInfo?.codeUrl || '';
    },
  },
  watch: {
    $route: function (v) {
      if (this.unAdWlChannel.includes(this.params.channel)) return;
      if (!v.path.includes('index')) return;

      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.preBack, false);
    },
    'params.actId': function (val) {
      /* if (val) {
        if (this.$route.path.includes('index')) {
          dotVISIT({
            act: val,
            actionIntro: '访问首页',
          });
        }
        if (this.$route.path.includes('success')) {
          dotVISIT({
            act: val,
            actionIntro: '访问成功页',
          });
        }
      } */
      if (
        getQueryParams('adid') &&
        getQueryParams('adid') == '__adgroup_id__' &&
        getQueryParams('channel') == 'tx'
      ) {
        if (
          [
            1208, 1247, 1238, 1281, 1290, 1286, 1300, 1306, 1313, 1327, 1333, 1315, 1341, 1342,
            1352, 1377,
          ].includes(val)
        ) {
          window.location.href = `${location.origin}/1343/bwenvc/index${location.search}`;
        }
        if ([1361].includes(val)) {
          // https://act6.tuborshu.com/1362/vz4zz8/index?chl=tbsyzgzjy&channel=tx&accountid=__account_id__&adid=__adgroup_id__&creativeid=__ad_id__
          window.location.href = `${location.origin}/1362/vz4zz8/index${location.search}`;
        }
      }
    },
  },
  methods: {
    hasActId(actIds) {
      return actIds.includes(this.params.actId);
    },
    initQuery() {
      this.getHost();
    },
    // 获取域名信息
    getHost() {
      let host = window.location.host;
      if (host.includes('ilives')) {
        this.host = 'xy';
      }
      if (host.includes('tuborshu.com')) {
        this.host = 'tbs';
      }
      if (host.includes('allunion')) {
        this.host = 'wwhy';
      }
      if (host.includes('baoqiliang')) {
        this.host = 'bql';
      }
      if (host.includes('dmsxx')) {
        this.host = 'xt';
      }
      if (host.includes('ejnjyc')) {
        this.host = 'dyc';
      }
      if (host.includes('everwin')) {
        this.host = 'ls';
        if (host.includes('xy01')) {
          this.host = 'hs';
        }
      }
      if (host.includes('junjue888')) {
        this.host = 'junjue';
      }

      if (host.includes('jlidushu')) {
        this.host = 'vivo';
      }
      if (host.includes('coo1read')) {
        this.host = 'ky';
      }
      if (host.includes('junjing998')) {
        this.host = 'yt';
      }
      if (host.includes('laidianbanlv')) {
        this.host = 'xiaowai';
      }
      if (host.includes('gzcsnt')) {
        this.host = 'cs'; //广州创搜网络科技有限公司
      }
      if (host.includes('ylqiliang')) {
        this.host = 'yl'; //广州创搜网络科技有限公司
      }
    },
    // 获取额外字段
    getExtra() {
      if (getQueryParams('channel') == 'sz') {
        this.query.extra = getQueryParams('sz_rli') ? getQueryParams('sz_rli') : '';
      }

      // 今日头条
      if (getQueryParams('channel') == 'jrtt' || getQueryParams('channel') == 'csj') {
        // 账户ID：advertiserId
        // advertiserId
        // 项目ID：projectid
        // 广告ID：promotionid
        // 图片素材ID：mid1
        // 视频素材ID：mid3
        // 媒体点位：unionsite
        let adid = getQueryParams('adid') ? getQueryParams('adid') : ''; //穿山甲计划id
        let promotionid = getQueryParams('promotionid') ? getQueryParams('promotionid') : ''; //穿山甲广告id
        let projectid = getQueryParams('projectid') ? getQueryParams('projectid') : ''; //穿山甲项目id
        let advertiserId = getQueryParams('advertiserId') ? getQueryParams('advertiserId') : ''; //穿山甲创意id
        let mid1 = getQueryParams('mid1') ? getQueryParams('mid1') : ''; //穿山甲创意id
        let mid3 = getQueryParams('mid3') ? getQueryParams('mid3') : ''; //穿山甲创意id

        this.query.extra = getQueryParams('clickid') ? getQueryParams('clickid') : '';
        this.query.extra2 = getQueryParams('union_site')
          ? getQueryParams('union_site')
          : getQueryParams('unionsite')
          ? getQueryParams('unionsite')
          : '';
        this.query.extra3 = JSON.stringify({
          adid,
          promotionid,
          projectid,
          advertiserId,
          mid1,
          mid3,
        });
      }
      // tuia
      if (getQueryParams('channel') == 'tuia' || getQueryParams('channel') == 'tuia2') {
        this.query.extra = getQueryParams('a_oId') ? getQueryParams('a_oId') : '';
        createScript('//yun.tuisnake.com/h5-mami/log_seed.js');
      }

      if (getQueryParams('channel') == 'ks') {
        this.query.extra = getQueryParams('callback') ? getQueryParams('callback') : '';
      }

      if (getQueryParams('channel') == 'tx') {
        this.query.extra = getQueryParams('qz_gdt')
          ? getQueryParams('qz_gdt')
          : getQueryParams('gdt_vid')
          ? getQueryParams('gdt_vid')
          : '';
        let adid = getQueryParams('adid') ? getQueryParams('adid') : ''; //腾讯广告id
        let accountid = getQueryParams('accountid') ? getQueryParams('accountid') : ''; //腾讯账户id
        let creativeid = getQueryParams('creativeid') ? getQueryParams('creativeid') : ''; //腾讯创意id
        this.query.extra3 = JSON.stringify({ adid, accountid, creativeid });

        if (getQueryParams('chl') == 'gzys') {
          let oHead = document.querySelector('head');
          console.log(oHead);
          let oScript = document.createElement('script');
          oScript.innerText =
            '!function (g, d, t, e, v, n, s) {\n' +
            '      if (g.gdt) return;\n' +
            '      v = g.gdt = function () {\n' +
            '        v.tk ? v.tk.apply(v, arguments) : v.queue.push(arguments);\n' +
            '      };\n' +
            '      v.sv = "1.0";\n' +
            '      v.bt = 0;\n' +
            '      v.queue = [];\n' +
            '      n = d.createElement(t);\n' +
            '      n.async = !0;\n' +
            '      n.src = e;\n' +
            '      s = d.getElementsByTagName(t)[0];\n' +
            '      s.parentNode.insertBefore(n, s);\n' +
            '    }(window, document, "script", "//qzonestyle.gtimg.cn/qzone/biz/gdt/dmp/user-action/gdtevent.min.js");\n' +
            '    gdt("init", "1201053485");\n' +
            '    gdt("init", "1201053500");\n' +
            '    gdt("init", "1201053504");\n' +
            '    gdt("init", "1201053040");\n' +
            '    gdt("track", "PAGE_VIEW");';
          oHead.appendChild(oScript);

          let oNoScript = document.createElement('noscript');
          oNoScript.innerHTML =
            '<img height="1" src="https://a.gdt.qq.com/pixel?user_action_set_id=1201053485&action_type=PAGE_VIEW&noscript=1" style="display:none" width="1"/>';
          oNoScript.innerHTML +=
            '<img height="1" src="https://a.gdt.qq.com/pixel?user_action_set_id=1201053500&action_type=PAGE_VIEW&noscript=1" style="display:none" width="1"/>';
          oNoScript.innerHTML +=
            '<img height="1" src="https://a.gdt.qq.com/pixel?user_action_set_id=1201053504&action_type=PAGE_VIEW&noscript=1" style="display:none" width="1"/>';
          oNoScript.innerHTML +=
            '<img height="1" src="https://a.gdt.qq.com/pixel?user_action_set_id=1201053040&action_type=PAGE_VIEW&noscript=1" style="display:none" width="1"/>';
          oHead.appendChild(oNoScript);
        }
      }
    },

    preBack() {
      this.$store.dispatch('ad/adWl', { step: 'back' });
    },
  },
  created() {
    this.initQuery();
  },
  mounted() {
    // this.$store.dispatch('hunan_cmcc/initScript', { actId: this.params.actId });
  },
};
</script>

<style lang="scss" scoped>
#app {
  width: 7.5rem;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  .page-container {
    flex: 1;
  }
}
</style>
