import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import '@/utils/rem';
import '@/style/reset.scss';
import '@/style/index.scss';

import '@/router/permission';

import { slotHasContent, pxToRem } from '@/utils/tools';

import { Toast, Popup, Lazyload } from 'vant';

Vue.use(Popup);
Vue.use(Lazyload);

import {
  xForm,
  CopyCap,
  Cap,
  Img,
  Module,
  Intro,
  Rule,
  RuleDialog,
  Agree,
  Ad,
  Policy,
  MobileSwiper,
  SubmitDialog,
  SubmitContentDialog,
  Beian,
} from './components';
Vue.component('xForm', xForm);
Vue.component('CopyCap', CopyCap);
Vue.component('Cap', Cap);
Vue.component('Img', Img);
Vue.component('Module', Module);
Vue.component('Intro', Intro);
Vue.component('Rule', Rule);
Vue.component('RuleDialog', RuleDialog);
Vue.component('Agree', Agree);
Vue.component('Ad', Ad);
Vue.component('Policy', Policy);
Vue.component('MobileSwiper', MobileSwiper);
Vue.component('SubmitDialog', SubmitDialog);
Vue.component('SubmitContentDialog', SubmitContentDialog);
Vue.component('Beian', Beian);

Vue.config.productionTip = false;

Vue.prototype.$slotHasContent = slotHasContent;
Vue.prototype.$pxToRem = pxToRem;
Vue.prototype.$toast = Toast;

// import vConsole from 'vconsole';
// if (process.env.NODE_ENV !== 'production') new vConsole();

/* const observer = new PerformanceObserver((list) => {
  list.getEntries().forEach((entry) => {
    console.log(entry);
  });
});

observer.observe({ entryTypes: ['resource', 'paint'] }); */

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
