<template>
  <div class="scroll-wrap" ref="scroll-wrap" :style="{ '--swiperWidth': -swiperWidth + 'px' }">
    <div class="swiper-scroll" ref="scroll">
      <div ref="mobile-item" class="item" v-for="(item, index) in list" :key="index">
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: '',
  computed: {
    ...mapGetters(['curProdJson']),
    topInfo: function () {
      return this.curProdJson.top || {};
    },
    swiperList: function () {
      return this[`${this.topInfo.mobileOp}List`] || this.cuccList;
    },
    list: function () {
      return this.swiperList.concat(this.swiperList.slice(0, 3));
    },
  },

  data() {
    return {
      swiperWidth: 0,
      cuccList: [
        '155****0736抢到了',
        '186****7032抢到了',
        '131****0830抢到了',
        '176****4264抢到了',
        '186****2136抢到了',
        '186****6693抢到了',
        '155****1436抢到了',
        '186****3520抢到了',
      ],
      cmccList: [
        '138****0736抢到了',
        '158****7032抢到了',
        '139****0830抢到了',
        '138****4264抢到了',
        '188****2136抢到了',
        '134****6693抢到了',
        '137****1436抢到了',
        '152****3520抢到了',
      ],
      ctccList: [
        '180****5236抢到了',
        '181****3564抢到了',
        '177****3595抢到了',
        '189****8958抢到了',
        '153****5416抢到了',
        '180****0467抢到了',
        '199****0442抢到了',
        '133****1105抢到了',
      ],
      mixList: [
        '180****5236抢到了',
        '181****3564抢到了',
        '177****3595抢到了',
        '155****0736抢到了',
        '186****7032抢到了',
        '131****0830抢到了',
        '180****0467抢到了',
        '138****4264抢到了',
        '188****2136抢到了',
      ],
    };
  },
  methods: {},
  created() {},
  mounted() {
    const _this = this;
    // console.log(_this.$refs['scroll-wrap']);
    this.$nextTick(() => {
      // console.log(this.$refs["mobile-item"]);
      for (let index = 0; index < this.swiperList.length; index++) {
        const element = this.$refs['mobile-item'][index];
        this.swiperWidth = this.swiperWidth + element.offsetWidth + 20;
      }

      setTimeout(() => {
        _this.$refs['scroll'].setAttribute('id', 'animation');
        clearTimeout();
      }, 10);
      /* this.$refs["mobile-item"].forEach((item) => {
				console.log(item.offsetWidth);
				this.swiperWidth = this.swiperWidth + item.offsetWidth + 20;
			}); */
      // console.log(this.swiperWidth);
    });
  },
};
</script>

<style scoped lang="scss">
@keyframes scroll {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
  100% {
    transform: translateX(var(--swiperWidth));
    -webkit-transform: translateX(var(--swiperWidth));
  }
}
.scroll-wrap {
  width: 7.5rem;
  overflow-x: hidden;
  .swiper-scroll {
    display: flex;
    // animation: scroll 10s linear infinite normal;
    .item {
      flex-shrink: 0;
      overflow: hidden;
      font-size: 0.24rem;
      padding: 0 30px;
      color: #fff;
      background-color: rgba($color: #000000, $alpha: 0.4);
      text-align: center;
      line-height: 0.6rem;
      border-radius: 0.3rem;
      & + .item {
        margin-left: 20px;
      }
    }
  }
  #animation {
    animation: scroll 10s linear infinite normal;
  }
}
</style>
