<template>
  <div>
    <Horizontal
      v-if="
        actTopInfo.hasMobileSwiper &&
        ![
          1244, 1247, 1265, 1269, 1270, 1281, 1283, 1290, 1286, 1300, 1310, 1306, 1309, 1313, 1323,
          1327, 1333, 1315, 1341, 1342, 1344, 1361, 1374, 1378, 1379,
        ].includes(params.actId)
      "
    />
    <Vertical
      :visible="
        actTopInfo.hasMobileSwiper &&
        [1270, 1310, 1309, 1341, 1342, 1344, 1361].includes(params.actId)
      "
    />
    <Vertical
      :visible="
        actTopInfo.hasMobileSwiper &&
        [
          1244, 1247, 1265, 1269, 1281, 1283, 1290, 1286, 1300, 1313, 1323, 1327, 1333, 1315, 1374,
          1378, 1379,
        ].includes(params.actId) &&
        isSendCode
      "
    />
  </div>
</template>

<script>
import Horizontal from './horizontal.vue';
import Vertical from './vertical.vue';
import { mapGetters } from 'vuex';
export default {
  name: '',
  components: { Horizontal, Vertical },
  computed: {
    ...mapGetters([
      'curProdJson',
      'configActJson',
      'ipProductId',
      'productId',
      'hasLoadConfig',
      'params',
      'currentRoute',
      'stepInfo',
      'isSendCode',
    ]),
    actTopInfo: function () {
      return this.configActJson?.top || {};
    },
  },
};
</script>
<style scoped lang="scss"></style>
