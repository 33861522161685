import axios from 'axios';

/**
 * 获取活动说明
 * @param {*} actId
 * @returns
 */
export function getRuleApi(actId) {
  const act5Url =
    'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/json/' +
    actId +
    '.json?t=' +
    new Date().getTime();
  const actbbUrl =
    'https://sgact.oss-cn-hangzhou.aliyuncs.com/actbb2/static/json/' +
    actId +
    '.json?t=' +
    new Date().getTime();
  return new Promise((resolve, reject) => {
    axios
      .get(act5Url, { timeout: 10000 })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        console.log(e);
        axios
          .get(actbbUrl, { timeout: 10000 })
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            // console.log(e);
            reject(e);
          });
      });
  });
}

/**
 * 获取手机号省份信息
 * @param {*} number
 * @returns
 */
export async function getProvinceApi(number) {
  const res = await axios.post('https://province360.ilives.com.cn/?number=' + number);
  return res.data;
}
