/**
 * 深拷贝
 * @param {*} source
 * @returns
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
  let result = '';
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    var part = encodeURIComponent(propName) + '=';
    if (value !== null && value !== '' && typeof value !== 'undefined') {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== '' && typeof value[key] !== 'undefined') {
            let params = propName + '[' + key + ']';
            var subPart = encodeURIComponent(params) + '=';
            result += subPart + encodeURIComponent(value[key]) + '&';
          }
        }
      } else {
        result += part + encodeURIComponent(value) + '&';
      }
    }
  }
  return result;
}

/**
 * 动态插入script标签，引入第三方jssdk
 * @param {*} src
 * @param {*} type
 * @returns
 */
export function createScript(src, type = 'insertBefore') {
  let sc = document.createElement('script');
  sc.src = src;
  if (type == 'insertBefore') {
    let tag = document.getElementsByTagName('script')[0];
    // script.async = true;

    tag.parentNode.insertBefore(sc, tag);
  } else {
    let head = document.getElementsByTagName('head')[0];
    head.appendChild(sc);
  }

  return new Promise((resolve, reject) => {
    if (window.ActiveXObject || 'ActiveXObject' in window) {
      //判断是否是ie
      if (sc.readyState) {
        //判断是否支持readyState
        sc.onreadystatechange = function () {
          if (this.readyState == 'loaded' || this.readyState == 'complete') {
            console.log('ie10及以下加载完成');
            resolve();
          }
        };
      } else {
        sc.onload = function () {
          console.log('ie11及Edge加载完成');
          resolve();
        };
        sc.onerror = function () {
          reject();
        };
      }
    } else {
      //不是ie
      sc.onload = function () {
        console.log('非ie浏览器加载完成');
        console.log(src);
        resolve();
      };
      sc.onerror = function () {
        console.log(src);
        reject(`非ie浏览器加载失败==${src}`);
      };
    }
  });
}

/**
 * 广东移动插入sdk
 * @param {*} window
 * @param {*} document
 * @param {*} script
 * @param {*} src
 * @param {*} namespace
 */
export function createYdDot(window, document, script, src, namespace) {
  window[namespace] =
    window[namespace] ||
    function () {
      (window[namespace].q = window[namespace].q || []).push(arguments);
    };
  script = document.createElement('script');
  let tag = document.getElementsByTagName('script')[0];
  script.async = true;
  script.src = src;
  tag.parentNode.insertBefore(script, tag);
}

/**
 * 获取链接参数
 * @param {*} paramsKey
 * @returns
 */
export const getQueryParams = (paramsKey) => {
  const query = window.location.href.split('?')[1];
  if (query) {
    const paramsList = query.split('&');
    // console.log(paramsList);
    for (var i = 0; i < paramsList.length; i++) {
      const key = paramsList[i].split('=')[0];
      const value = paramsList[i].split('=')[1];
      if (key === paramsKey) {
        return value;
      }
    }
  } else {
    return '';
  }
};

/**
 * 遍历两个对象，返回一个新对象，以 obj1 的值优先
 * @param {*} obj1
 * @param {*} obj2
 * @returns
 */
export function mapObject(a, b, compulsion) {
  const c = deepClone(a);
  Object.keys(b).forEach((key) => {
    if (typeof a[key] === 'number' || typeof a[key] === 'boolean') {
      c[key] = a[key];
    } else if (b[key] === null && a[key] === null) {
      c[key] = b[key];
    } else if (b[key] !== null && a[key] !== null) {
      const typeA = typeof a[key];
      const typeB = typeof b[key];
      if (typeB === 'object' && typeA === 'object') {
        // c[key] = mapObject(a[key], b[key]);
        if (Array.isArray(a[key]) && Array.isArray(b[key]) && compulsion) {
          c[key] = a[key];
        } else {
          c[key] = mapObject(a[key], b[key], compulsion);
        }
      } else if (typeB !== 'object' && typeA !== 'object') {
        c[key] = a[key] || b[key];
      } else if (typeA === 'object') {
        c[key] = a[key];
      } else {
        c[key] = b[key];
      }
    } else {
      c[key] = a[key] || b[key];
    }
  });
  return c;
}
/* export function mapObject(obj1, obj2) {
  // const commonObj = deepClone(Object.assign({}, obj1, obj2));
  let commonObj;
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    commonObj = deepClone(Object.assign([], obj1, obj2));
  } else {
    commonObj = deepClone(Object.assign({}, obj1, obj2));
  }
  for (const key in commonObj) {
    if (typeof commonObj[key] == 'object' && commonObj[key] !== null && obj1[key]) {
      commonObj[key] = mapObject(obj1[key], commonObj[key]);
      // if (Array.isArray(commonObj[key])) {
      //   if (Array.isArray(obj1[key]) && obj1[key].length) commonObj[key] = obj1[key];
      // } else {
      //   commonObj[key] = mapObject(obj1[key], commonObj[key]);
      // }
    } else {
      if (
        (typeof commonObj[key] == 'number' || typeof commonObj[key] == 'boolean') &&
        (typeof obj1[key] == 'number' || typeof obj1[key] == 'boolean')
      ) {
        commonObj[key] = obj1[key];
      } else {
        commonObj[key] = obj1[key] || commonObj[key];
      }
    }
  }
  return commonObj;
} */
/* export function mapObject(defauleObj, currentObj) {
	const commonObj = Object.assign({}, defauleObj, currentObj);
	for (const key in commonObj) {
		// console.log(key, typeof commonObj[key]);
		if (typeof commonObj[key] == "object" && commonObj[key] !== null) {
			if (Array.isArray(commonObj[key])) {
				// if (!commonObj[key].length) commonObj[key] = defauleObj[key];
				if (Array.isArray(obj1[key]) && obj1[key].length) commonObj[key] = obj1[key];
			} else {
				commonObj[key] = mapObject(defauleObj[key], commonObj[key]);
			}
		} else {
			commonObj[key] = commonObj[key] || defauleObj[key];
		}
	}
	return commonObj;
} */

/**
 * 获取各推广平台回传参数
 * @param {*} query
 * @returns
 */
export function getExtra(query) {
  const { chl, channel, callbackUrl, ...rest } = query;
  const searchParams = {
    chl: chl || '',
    channel: channel || '',
    callbackUrl: chl == 'gunshi' && callbackUrl ? callbackUrl : '',
  };

  if (channel == 'jrtt' || channel == 'csj') {
    searchParams.extra = rest.clickid || '';
    searchParams.extra2 = rest.union_site || rest.unionsite || '';
    searchParams.extra3 = JSON.stringify({
      adid: rest.adid || '',
      promotionid: rest.promotionid || '',
      projectid: rest.projectid || '',
      advertiserId: rest.advertiserId || '',
      mid1: rest.mid1 || '',
      mid3: rest.mid3 || '',
    });
  } else if (channel == 'ks') {
    searchParams.extra = rest.callback || '';
    searchParams.extra3 = JSON.stringify({
      accountid: rest.accountid || '',
      aid: rest.aid || '',
      cid: rest.cid || '',
      did: rest.did || '',
      photoid: rest.photoid || '',
    });
  } else if (channel == 'tx') {
    searchParams.extra = rest.qz_gdt || rest.gdt_vid || '';
    searchParams.extra3 = JSON.stringify({
      adid: rest.adid || '',
      accountid: rest.accountid || '',
      creativeid: rest.creativeid || '',
    });
  } else if (channel == 'sz') {
    searchParams.extra = rest.sz_rli || '';
  } else if (channel == 'tuia') {
    searchParams.extra = rest.a_oId || '';
  }

  return searchParams;
}

/**
 * 控制页面平滑滚动到某一位置
 * @param {*} dom
 * @param {*} num
 */
export function pageScrollHandle(dom, num = 0) {
  console.log(dom.offsetTop);
  window.scrollTo({
    top: dom.offsetTop + num,
    behavior: 'smooth',
  });
}

/**
 * 判断插槽是否有内容
 * @param {*} slotName
 * @returns
 */
export function slotHasContent(slotName) {
  return this.$slots[slotName] && this.$slots[slotName].length > 0;
}

/**
 * px转换为rem
 * @param {*} pxValue
 * @param {*} parentWidth
 * @returns
 */
export function pxToRem(pxValue, parentWidth = 375) {
  // console.log(pxValue, typeof pxValue);
  const pxV = typeof pxValue == 'string' && pxValue ? pxValue : pxValue.toString();

  const px = pxV.includes('px') ? pxV.split('px')[0] : pxV;
  return px / ((parentWidth / 750) * 100) + 'rem';
}

/**
 * 获取json字符串
 * @param {*} jsonString
 * @returns
 */
export function getJson(jsonString) {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return {};
  }
}

/**
 * 判断某个时刻是不是在某个时间段内
 * 例：09:30在09:00-10:00内，返回true
 */
export function isInTime({ nowTime, startTime, endTime }) {
  const [startHour, startMinute] = startTime.split(':');
  const [endHour, endMinute] = endTime.split(':');

  let nowHour, nowMinute;

  if (nowTime) {
    nowHour = nowTime.split(':')[0];
    nowMinute = nowTime.split(':')[1];
  } else {
    nowHour = new Date().getHours();
    nowMinute = new Date().getMinutes();
  }

  const startTimeStamp = parseInt(startHour) * 60 + parseInt(startMinute);
  const endTimeStamp = parseInt(endHour) * 60 + parseInt(endMinute);
  const nowTimeStamp = parseInt(nowHour) * 60 + parseInt(nowMinute);

  if (startHour >= endHour && startMinute >= endMinute) {
    return nowTimeStamp >= startTimeStamp || nowTimeStamp < endTimeStamp;
  } else {
    return nowTimeStamp >= startTimeStamp && nowTimeStamp < endTimeStamp;
  }
}

/**
 * 根据requestAnimationFrame实现setInterval
 * @param {*} callback
 * @param {*} interval
 * @returns
 */
export function requestInterval(callback, interval) {
  let start = performance.now();
  let handle = null;

  function loop(now) {
    if (now - start >= interval) {
      start = now;
      callback();
    }
    handle = requestAnimationFrame(loop);
  }

  handle = requestAnimationFrame(loop);

  return handle;
}

/**
 * 实现随机数功能，根据随机数范围，返回一个随机数
 */
export function randomNum(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
