import router from './index';
import store from '@/store';
// import { routes } from './index';

import { getExtra } from '@/utils/tools';
import { getActIdByCode } from '@/api/index';

// const modules = import.meta.glob("../pages/**/*.vue");
// console.log(modules);
// console.log(require("@/pages/success/index.vue").default);

router.beforeEach(async (to, from, next) => {
  console.log('开始');
  store.commit('config/SET_CURRENT_ROUTE', to);
  // console.log(to);
  // const actId = Number(to.path.split("/")[1]) || "";
  let actId;
  const saltValue = to.path.split('/').reverse()[1] || '';
  const searchParams = getExtra(to.query);
  // console.log(store.state.permission.isAddRoute);

  /* if (actId && to.path.includes("index")) {
		// 判断页面是否打开

		try {
			const res = await getOpenAct(actId);
			if (!res.data.notFound) {
				next("/404?redirect=" + to.path);
			}
		} catch (error) {
      console.log(error);
    }

		// 广东移动插码
		store.dispatch("gdyd/init_gdyd", { actId });
		// 初始化广告信息（引入第三方广告sdk）
		store.dispatch("ad/initAd", { actId });
		// 获取页面配置信息
		store.commit("order/SET_PARAMS", { ...store.getters.params, ...searchParams, actId });
		store.dispatch("config/preOrderByIp").then(() => {
			store.dispatch("config/getConfigPage", { actId });
		});
	} */
  // next();

  const route = await store.dispatch('permission/generateRoutes', {
    path: to.path,
  });

  if (!store.state.permission.isAddRoute && route) {
    console.log('开始生成');

    router.addRoute(route);
    store.commit('permission/SET_ISADDROUTE', true);
    return next({ ...to, replace: true });

    // next();
  } else {
    store.commit('permission/SET_ISADDROUTE', false);
    // console.log('判断页面是否打开');
    // 判断页面是否打开  判断页面路径的正则表达式:/^(\/v2\/[a-z0-9]{6}\/(index))$/   "/v2/u3x2lh/index"
    if (to.path.includes('index')) {
      try {
        // console.log(saltValue);
        console.log('获取页面状态');
        const res = await getActIdByCode({ saltValue });
        // console.log(res);
        if (res.code == 1) {
          actId = res.data?.actId;
          if (!actId || !res.data.notFound) {
            // console.log('页面获取失败=====404');
            return next('/404?redirect=' + to.path);
          }
        }
      } catch (error) {
        console.log(error);
      }

      // 广东移动插码
      store.dispatch('gdyd/init_gdyd', { actId });
      // 初始化广告信息（引入第三方广告sdk）
      store.dispatch('ad/initAd', { actId });
      // 获取页面配置信息
      store.commit('order/SET_PARAMS', {
        ...store.getters.params,
        ...searchParams,
        actId,
      });
      // console.log(to);
      // 有号码时自动下单
      /* if (to.query.mobile) {
        store.dispatch('order/preOrder', {
          ...store.getters.params,
          mobile: to.query.mobile,
        });
      } */

      console.log('获取页面配置信息');
      const configRes = await store.dispatch('config/getConfigPage', { actId });
      if (!configRes.actJson && !configRes.productInfo) {
        return next('/404?redirect=' + to.path);
      }
      store.dispatch('config/preOrderByIp').then(() => {
        // 湖南移动插码
        store.dispatch('hunan_cmcc/initScript', { actId });
      });
      /* store.dispatch('config/getConfigPage', { actId }).then((res) => {
        if (!res.actJson && !res.productInfo) {
          return next('/404?redirect=' + to.path);
        }
        store.dispatch('config/preOrderByIp').then(() => {
          // 湖南移动插码
          store.dispatch('hunan_cmcc/initScript', { actId });
        });
      }); */
    }

    console.log('结束');
    return next();
  }
});
