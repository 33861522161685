var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.actTopInfo.hasMobileSwiper &&
      ![
        1244, 1247, 1265, 1269, 1270, 1281, 1283, 1290, 1286, 1300, 1310, 1306, 1309, 1313, 1323,
        1327, 1333, 1315, 1341, 1342, 1344, 1361, 1374, 1378, 1379,
      ].includes(_vm.params.actId)
    )?_c('Horizontal'):_vm._e(),_c('Vertical',{attrs:{"visible":_vm.actTopInfo.hasMobileSwiper &&
      [1270, 1310, 1309, 1341, 1342, 1344, 1361].includes(_vm.params.actId)}}),_c('Vertical',{attrs:{"visible":_vm.actTopInfo.hasMobileSwiper &&
      [
        1244, 1247, 1265, 1269, 1281, 1283, 1290, 1286, 1300, 1313, 1323, 1327, 1333, 1315, 1374,
        1378, 1379,
      ].includes(_vm.params.actId) &&
      _vm.isSendCode}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }