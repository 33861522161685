const getters = {
  currentRoute: (state) => state.config.currentRoute,
  ruleJson: (state) => state.config.ruleJson,

  params: (state) => state.order.params,
  preOrderData: (state) => state.order.preOrderData,
  orderData: (state) => state.order.orderData,
  payData: (state) => state.order.payData,
  wlData: (state) => state.order.wlData,

  failOrderProductIdList: (state) => state.order.failOrderProductIdList,

  province: (state) => state.order.province,
  city: (state) => state.order.city,
  op: (state) => state.order.op,

  capFlag: (state) => state.order.capFlag, // cap类型（0: 默认 1: cap集成 2: 伪cap）
  fsrc: (state) => state.order.fsrc, // cap集成链接
  hasValidate: (state) => state.order.hasValidate, // cap页面是否有验证码
  isCover: (state) => state.order.isCover, // cap页面顶部是否遮挡

  checked: (state) => state.order.checked,
  isSendCode: (state) => state.order.isSendCode,
  codeContent: (state) => state.order.codeContent,
  codeTimer: (state) => state.order.codeTimer,
  codeTime: (state) => state.order.codeTime,
  showPaySubmit: (state) => state.order.showPaySubmit,
  currentPaySubmitInfo: (state, getters) => getters['order/currentPaySubmitInfo'],

  hasLoadConfig: (state) => state.config.hasLoadConfig,
  configActJson: (state) => state.config.configActJson,
  configProduct: (state) => state.config.configProduct,
  curProdJson: (state, getters) => getters['config/curProdJson'],

  actStepInfo: (state) => state.config.actStepInfo,
  stepInfo: (state, getters) => getters['config/stepInfo'],
  currentStep: (state) => state.config.currentStep,

  ipProductInfo: (state) => state.config.ipProductInfo,
  ipProductId: (state, getters) => getters['config/ipProductId'],
  ipProvince: (state, getters) => getters['config/ipProvince'],
  ipCity: (state, getters) => getters['config/ipCity'],

  productId: (state, getters) =>
    state.order.preOrderData?.productId || getters['config/ipProductId'],

  unAdWlChannel: (state) => state.ad.unAdWlChannel,
  adModel: (state) => state.ad.adModel,
  selfAdList: (state) => state.ad.selfAdList,

  showPolicyGdlt: (state) => state.config.showPolicyGdlt,
  showRuleDialog: (state) => state.config.showRuleDialog,

  byteFormVisible: (state) => state.byte_mobile.byteFormVisible,
  createFormByte: (state) => state.byte_mobile.createFormByte,
  byteDialogVisible: (state) => state.byte_mobile.byteDialogVisible,
  createDialogByte: (state) => state.byte_mobile.createDialogByte,
  byteMobile: (state) => state.byte_mobile.byteMobile,
};

export default getters;
