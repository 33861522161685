/* eslint-disable no-undef */
import { createScript } from '@/utils/tools';
import { encode } from 'js-base64';
import { newPreOrderApi } from '@/api';
import { dotWL, dotCLICK } from '@/api/dot';

import { Toast } from 'vant';

const state = {
  unAdWlActId: [],
  unAdWlChannel: ['tx', 'ks'],

  // 流量果广告参数
  llg: {
    hasLLGSDK: false,
    // actIds: [724, 728, 637, 904, 774],
    actIds: [643, 1236, 1209],
    default: {
      app_key: 'LLG10005456',
      app_secret: '50eda5f069c599de3f5c5ed5e709ae34',
    },
    mediaList: {
      back: {
        /* 724: {
          ad_position_id: '100444',
        },
        728: {
          ad_position_id: '100444',
        },
        774: {
          ad_position_id: '100444',
        },
        904: {
          ad_position_id: '100444',
        },
        637: {
          ad_position_id: '102345',
        }, */
        default: {
          // ad_position_id: '100444',
          ad_position_id: '104001',
        },
      },
      preOrder: {
        /* 724: {
          ad_position_id: '100441',
        },
        728: {
          ad_position_id: '100441',
        },
        774: {
          ad_position_id: '100441',
        },
        904: {
          ad_position_id: '100441',
        },
        637: {
          ad_position_id: '102342',
        }, */
        default: {
          // ad_position_id: '100441',
          ad_position_id: '104002',
        },
      },
      order: {
        /* 724: {
          ad_position_id: '100442',
        },
        728: {
          ad_position_id: '100442',
        },
        774: {
          ad_position_id: '100442',
        },
        904: {
          ad_position_id: '100442',
        },
        637: {
          ad_position_id: '102484',
        }, */
        default: {
          // ad_position_id: '100442',
          ad_position_id: '104002',
        },
      },
    },
    init() {
      if (!this.hasLLGSDK) {
        // createScript('https://assets.wi-fi.cn/assets/js/liuliangguoSDK_min.js?v=1.0.4');
        createScript('https://assets.wi-fi.cn/assets/js/liuliangguoSDK_min.js?v=1.2.1');
      }
    },
    wl(params, res, step = 'preOrder') {
      console.log(this);
      console.log(params);
      const { actId, channel, mobile } = params || {};
      const mediaKey = actId && channel ? actId + '_' + channel : '';
      const media = this.mediaList[step][mediaKey] || this.mediaList[step][actId] || '102345';
      console.log(media);
      if (media) {
        let llgSDK = typeof llgsdk === 'undefined' ? '' : llgsdk;

        llgSDK &&
          llgSDK.getAdAndShow(
            {
              ...this.default,
              phone: mobile || '',
              // ad_position_id: "100442",
              ...media,
            },
            function (result) {
              console.log(result);
              if (!result.is_success) {
                // wl(params, res);
                //获取广告失败执行动作，正常是弹框展示原来的失败原因给用户提醒，如alert('用户办理失败原因')
              }
            },
          );
      }
    },
  },

  // 流量宝广告参数
  llb: {
    hasLLBSDK: false,
    // actIds: [812, 810, 645, 680, 1034],
    actIds: [],
    mediaList: {
      back: {
        /* 812: {
          ad_position_id: 1262,
          media_id: 402,
        },
        810: {
          ad_position_id: 1264,
          media_id: 398,
        },
        645: {
          ad_position_id: 1309,
          media_id: 411,
        },
        680: {
          ad_position_id: 1354,
          media_id: 426,
        },
        1034: {
          ad_position_id: 1393,
          media_id: 440,
        }, */
        default: {
          // ad_position_id: 1393,
          ad_position_id: 1670,
          // media_id: 440,
          media_id: 524,
        },
      },
      preOrder: {
        /* 812: {
          ad_position_id: 1261,
          media_id: 402,
        },
        810: {
          ad_position_id: 1263,
          media_id: 398,
        },
        645: {
          ad_position_id: 1310,
          media_id: 411,
        },
        680: {
          ad_position_id: 1353,
          media_id: 426,
        },
        1034: {
          ad_position_id: 1394,
          media_id: 440,
        }, */
        default: {
          // ad_position_id: 1394,
          ad_position_id: 1669,
          // media_id: 440,
          media_id: 525,
        },
      },
      order: {
        /* 812: {
          ad_position_id: 1261,
          media_id: 402,
        },
        810: {
          ad_position_id: 1263,
          media_id: 398,
        },
        645: {
          ad_position_id: 1310,
          media_id: 411,
        },
        680: {
          ad_position_id: 1353,
          media_id: 426,
        },
        1034: {
          ad_position_id: 1394,
          media_id: 440,
        }, */
        default: {
          // ad_position_id: 1394,
          ad_position_id: 1669,
          // media_id: 440,
          media_id: 525,
        },
      },
    },
    init(
      params = {
        key: '59457602',
        secret: '58u8e6xuwfovlsp5',
      },
    ) {
      if (!this.hasLLBSDK) {
        createScript('https://static.ejcop.com/gluttony/page/yiZunAd.min.js').then(() => {
          initYiZunAd(params);
        });
      }
    },
    default: {
      ad_position_id: '',
      media_id: '',
      conId: 'yiZunAdContainer',
      displayType: 'modal',
      phone: '',
      goods_name: '',
      auto: false,
    },
    wl(params, res, step = 'order') {
      console.log(this);
      const { data } = res || {};
      const { productName } = data || {};
      const { actId, channel, mobile } = params || {};
      const mediaKey = actId && channel ? actId + '_' + channel : '';
      const media = this.mediaList[step][mediaKey] || this.mediaList[step][actId] || '';
      if (media) {
        renderYiZunAd({
          ...media,
          conId: 'yiZunAdContainer',
          displayType: 'modal',
          phone: mobile || '',
          goods_name: productName,
          auto: false,
          msg: '办理失败！',
        }).then((result) => {
          console.log(result);
          if (result.success) {
            console.log('调用成功');
          } else {
            console.log('调用失败');
            // wl(params, res);
          }
        });
      }
    },
  },

  // 发财树广告参数
  recsdk: {
    hasRECSDK: false,
    // actIds: [502, 746, 907, 738, 938, 945, 822, 1028, 1200],
    actIds: [], // 除llg和llb都跳发财树尾流
    mediaList: {
      back: {
        default: {
          media: '202412021627218041',
          isp: 'ISP-LT',
          pointNum: 'home_leave',
        },
      },
      preOrder: {
        default: {
          media: '202412021627312048',
          isp: 'ISP-LT',
          pointNum: 'order_fail',
        },
      },
      order: {
        default: {
          media: '202412021627312048',
          isp: 'ISP-LT',
          pointNum: 'order_fail',
        },
      },
      /* 738: {
        media: '202403071736200788',
        isp: 'ISP-LT',
      },
      774: {
        media: '202403071740541367',
        isp: 'ISP-LT',
      },
      502: {
        media: '202403151515500096',
        isp: 'ISP-LT',
      },
      746: {
        media: '202404161747159787',
        isp: 'ISP-LT',
      },
      904: {
        media: '202404161749523468',
        isp: 'ISP-LT',
      },
      907: {
        media: '202405071530513693',
        isp: 'ISP-LT',
      },
      914: {
        media: '202405071531246604',
        isp: 'ISP-CMCC',
      },
      802: {
        media: '202405231543336941',
        isp: 'ISP-CMCC',
      },
      938: {
        media: '202405241433130829',
        isp: 'ISP-CMCC',
      },
      945: {
        media: '202405241433130829',
        isp: 'ISP-CMCC',
      },
      822: {
        media: '202405241433130829',
        isp: 'ISP-CMCC',
      },
      690: {
        media: '202404161747159787',
        isp: 'ISP-CMCC',
      },
      730: {
        media: '202404161747159787',
        isp: 'ISP-LT',
      },
      1028: {
        media: '202406201452452368',
        isp: 'ISP-LT',
      },
      1200: {
        media: '202408091703394221',
        isp: 'ISP-LT',
      }, */
      default: {
        media: '202412021627218041',
        isp: 'ISP-LT',
        pointNum: 'home_leave',
      },
    },
    init(unionKey = '98c860a472b541b89b6254a3b96c2f67') {
      if (!this.hasRECSDK) {
        createScript(
          'https://staticcdntx.xwtec.cn/static/taobaoH5/busi/fs/js/sdk/rec_00ad8c2295.min.js',
        )
          .then(() => {
            window.sdk = recsdk.init({
              sessionId: localStorage.getItem('UUID'),
              unionKey,
            });
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    default: {
      pointNum: '',
      media: '',
      isp: '',
      phoneNum: '',
    },
    wl(params, res, step = 'send_fail') {
      const { actId, channel, mobile } = params || {};
      const mediaKey = actId && channel ? actId + '_' + channel : '';
      const media = this.mediaList[mediaKey] || this.mediaList[actId] || '';
      if (media) {
        sdk.getAd({ pointNum: step, ...media, phoneNum: mobile || '' }, (err) => {
          // 获取失败的回调 - 可自行处理错误流程
          console.log(err);
          // wl(params, res);
        });
      }
    },
  },

  // 骏伯-增收宝
  junbo: {
    hasJUNBOSDK: false,
    actIds: [1022],
    default: {
      channel_id: '20240516115158610236001',
      channel_secret: 'xiangyu123456',
    },
    mediaList: {
      preOrder: {
        1022: {
          ad_position_id: '571001',
        },
      },
      order: {
        1022: {
          ad_position_id: '571001',
        },
      },
    },
    async init() {
      if (!this.hasJUNBOSDK) {
        await createScript('https://cdn.lipush.com/junbo/sdk/zengshoubao@1.0.6.js');
        return;
      }
    },
    wl(params, res, step = 'preOrder') {
      console.log(this);
      const { actId, channel, mobile } = params || {};
      const mediaKey = actId && channel ? actId + '_' + channel : '';
      const media = this.mediaList[step][mediaKey] || this.mediaList[step][actId] || '';
      if (media) {
        JunboSDK &&
          JunboSDK.getAdAndShow(
            {
              ...this.default,
              phone: mobile || '',
              scenes: 'order_failed',
              ...media,
            },
            function (res) {
              console.log(res);
              console.log('骏伯', res.message);
            },
          );
      }
    },
  },

  // 自有
  adModel: false,
  selfAdList: [],
};

const mutations = {
  SET_AD_MODEL: (state, adModel) => {
    state.adModel = adModel;
  },
  SET_AD_LIST: (state, selfAdList) => {
    state.selfAdList = selfAdList;
  },
};

const actions = {
  initAd({ state }) {
    // const { params } = rootGetters;
    /* const { actId } = params || {};
    if (state.llg.actIds.includes(actId)) {
      console.log('llg-init');
      state.llg.init();
    }
    if (state.llb.actIds.includes(actId)) {
      console.log('llb-init');
      state.llb.init();
    }
    if (state.recsdk.actIds.includes(actId)) {
      console.log('recsdk-init');
      state.recsdk.init();
    }
    if (state.junbo.actIds.includes(actId)) {
      console.log('junbo-init');
      state.junbo.init();
    } */
    state.llg.init();
    state.recsdk.init();
    state.llb.init();
    // createScript();
  },
  llgWl({ state }, { params, step = 'order' }) {
    console.log('llgWl');
    const { llg } = state;
    const { actId, chl, channel, mobile } = params || {};
    /* const mediaKey = actId && channel ? actId + '_' + channel : actId ? actId : 'default';
    const media = llg.mediaList[step][mediaKey] || llg.mediaList[step]['default']; */

    const mediaStepParams = llg.mediaList[step] || llg.mediaList['order'];
    /* const media =
      mediaStepParams[`${actId}_${channel}`] ||
      mediaStepParams[actId] ||
      mediaStepParams['default']; */
    const media = mediaStepParams['default'];

    // console.log('LLG', media);

    if (!media) return;

    dotWL({
      act: actId,
      actionIntro: '第三方sdk广告-llgAd',
      chl,
      channel,
      queryKey: 'mobile',
      queryValue: mobile,
      queryKeyNd: 'WL',
      queryValueNd: 'llgAd',
      queryKeyRd: 'STEP',
      queryValueRd: step || 'order',
      reqParams: JSON.stringify(params),
      respParams: JSON.stringify({ ...media, phone: mobile, step }),
    });

    let llgSDK = typeof llgsdk === 'undefined' ? '' : llgsdk;
    llgSDK &&
      llgSDK.getAdAndShow(
        {
          ...llg.default,
          phone: mobile,
          ...media,
        },
        function (result) {
          dotCLICK({
            act: actId,
            actionIntro: '第三方sdk广告-llgAd',
            chl,
            channel,
            queryKey: 'mobile',
            queryValue: mobile,
            queryKeyRd: 'STEP',
            queryValueRd: step || 'order',
            reqParams: JSON.stringify({
              phone: mobile,
              ...media,
              step,
            }),
            respParams: JSON.stringify(result),
          });
          console.log(result);
          if (!result.is_success) {
            // wl(params, res);
            //获取广告失败执行动作，正常是弹框展示原来的失败原因给用户提醒，如alert('用户办理失败原因')
          }
        },
      );
  },
  llbWl({ state, rootGetters }, { params, step = 'order' }) {
    console.log('llbWl');
    const { llb } = state;
    const { preOrderData, orderData } = rootGetters || {};
    const { productName } = orderData || preOrderData || {};
    const { actId, chl, channel, mobile } = params || {};

    const mediaStepParams = llb.mediaList[step] || llb.mediaList['order'];
    /* const media =
      mediaStepParams[`${actId}_${channel}`] ||
      mediaStepParams[actId] ||
      mediaStepParams['default']; */
    const media = mediaStepParams['default'];

    // const mediaKey = actId && channel ? actId + '_' + channel : actId ? actId : 'default';
    /* const media = llb.mediaList[step][mediaKey] ||
      llb.mediaList[step]['default'] || { media_id: 424, ad_position_id: 1352 }; */
    // console.log(media);
    // console.log(preOrderData);
    // if (process.env.NODE_ENV == 'development') return;
    if (!media) return;

    dotWL({
      act: actId,
      actionIntro: '第三方sdk广告-llbAd',
      chl,
      channel,
      queryKey: 'mobile',
      queryValue: mobile,
      queryKeyNd: 'WL',
      queryValueNd: 'llbAd',
      queryKeyRd: 'STEP',
      queryValueRd: step || 'order',
      reqParams: JSON.stringify({ actId, chl, channel, mobile, productName }),
      respParams: JSON.stringify({ ...media, phoneNum: mobile, step }),
    });
    renderYiZunAd({
      ...media,
      conId: 'yiZunAdContainer',
      displayType: 'modal',
      phone: mobile || '',
      goods_name: productName || 'xy尾流',
      auto: false,
      msg: '办理失败！',
    }).then((result) => {
      console.log(result);
      dotCLICK({
        act: actId,
        actionIntro: '第三方sdk广告-llbAd',
        chl,
        channel,
        queryKey: 'mobile',
        queryValue: mobile,
        reqParams: JSON.stringify({
          ...media,
          actId,
          chl,
          channel,
          mobile,
          productName,
          conId: 'yiZunAdContainer',
          displayType: 'modal',
          step,
        }),
        respParams: JSON.stringify(result),
      });
      if (result.success) {
        console.log('调用成功');
      } else {
        console.log('调用失败');
        // wl(params, res);
      }
    });
  },
  recsdkWl({ state }, { params, step = 'order' }) {
    console.log('recsdkWl');
    const { recsdk } = state;
    const { mediaList } = recsdk || {};
    const { actId, chl, channel, mobile } = params || {};

    // const media = mediaList[`${actId}_${channel}`] || mediaList[actId] || mediaList['default'];

    if (!mobile) step = 'back';

    const mediaStepParams = mediaList[step] || mediaList['order'];

    const media = mediaStepParams['default'];

    if (!media) return;

    try {
      dotWL({
        act: actId,
        actionIntro: '第三方sdk广告-recsdkAd',
        chl,
        channel,
        queryKey: 'mobile',
        queryValue: mobile,
        queryKeyNd: 'WL',
        queryValueNd: 'recsdkAd',
        queryKeyRd: 'STEP',
        queryValueRd: step || 'order',
        reqParams: JSON.stringify(params),
        respParams: JSON.stringify({ ...media, phoneNum: mobile, step }),
      });
    } catch (error) {
      console.log(error);
    }

    try {
      sdk.getAd({ ...media, phoneNum: mobile || '' }, (err) => {
        try {
          dotCLICK({
            act: actId,
            actionIntro: '第三方sdk广告-recsdkAd',
            chl,
            channel,
            queryKey: 'mobile',
            queryValue: mobile,
            reqParams: JSON.stringify({ pointNum: step, ...media, phoneNum: mobile, step }),
            respParams: JSON.stringify(err),
          });
        } catch (error) {
          console.log(error);
        }

        // 获取失败的回调 - 可自行处理错误流程
        console.log(err);
        // wl(params, res);
      });
    } catch (error) {
      console.log(error);
    }
  },
  junboWl({ state }, { params, step = 'preOrder' }) {
    console.log(this);
    const { junbo } = state;
    const { actId, channel, mobile } = params || {};
    const mediaKey = actId && channel ? actId + '_' + channel : '';
    const media = junbo.mediaList[step][mediaKey] || junbo.mediaList[step][actId] || '';
    if (media) {
      JunboSDK &&
        JunboSDK.getAdAndShow(
          {
            ...junbo.default,
            phone: mobile || '',
            scenes: 'order_failed',
            ...media,
          },
          function (res) {
            console.log(res);
            console.log('骏伯', res.message);
          },
        );
    }
  },

  toSelfAd({ rootGetters }, { adUrl }) {
    // console.log()
    const { params } = rootGetters; // preOrderData, orderData,
    const { actId, chl, channel, mobile } = params || {};
    try {
      dotWL({
        actionIntro: '点击保险广告',
        act: actId,
        chl: chl,
        channel: channel,
        reqParams: JSON.stringify(params),
        queryKey: 'wl',
        queryValue: adUrl,
      });
    } catch (error) {
      console.log(error);
    }

    dotWL({
      act: actId,
      actionIntro: '自有尾流-XY',
      chl,
      channel,
      queryKey: 'mobile',
      queryValue: mobile,
      queryKeyNd: 'WL',
      queryValueNd: 'XY',
      reqParams: JSON.stringify(params),
      respParams: adUrl,
    });

    if (adUrl) window.location.href = adUrl;
  },
  async selfWl({ state, dispatch, rootGetters }, { params, step = 'preOrder' }) {
    const { mobile } = params || {};
    const { failOrderProductIdList, wlData, province, op } = rootGetters; // preOrderData, orderData,

    const { skipUrl } = wlData || {};
    console.log(wlData);
    // console.log(orderData, preOrderData);

    if (failOrderProductIdList.length >= 3) {
      // dispatch('llgWl', { params, step });
      dispatch('adWl', { step: 'preOrder', fromWl: true });
      return;
    }
    const preRes = await newPreOrderApi({
      actId: 1200,
      chl: 'xy',
      channel: 'wl',
      mobile,
    });
    // console.log(preRes);
    // if (process.env.NODE_ENV == 'development') return;
    if (preRes.code == 40006) {
      /* if (province == '江苏' && op == '联通') {
            dispatch('toSelfAd', {
              adUrl:
                'https://actjs.ilives.com.cn/micropage-touliu/pages/otherMp/index?pageId=9092316331301777&channelId=3&s=100000233&chl=xyjglrz&channel=csj',
            });
          } else { */
      dispatch('adWl', { step: 'preOrder', fromWl: true });
      // }
      return;
    }
    let adUrl = skipUrl || 'https://act4.ilives.com.cn/1200/l8n0q5/index?chl=xy&channel=wl';
    const wlUrlParams = {
      mobile,
      failProductId: JSON.stringify(failOrderProductIdList),
    };
    if (preRes.code == 1 && Array.isArray(preRes.data)) {
      wlUrlParams.productId = preRes.data[0].productId;
    }
    const wlUrlSearchParams = new URLSearchParams(wlUrlParams).toString();

    adUrl = `${adUrl}${adUrl.includes('?') ? '&' : '?'}${wlUrlSearchParams}`;
    // console.log(adUrl);

    dispatch('toSelfAd', { adUrl });
  },

  adWl({ state, dispatch, rootGetters }, par) {
    console.log('尾流');
    const { unAdWlChannel, llg, llb } = state;
    const { step = 'order', fromWl } = par || {};
    const { params } = rootGetters;
    const { actId, channel } = params || {};

    // if (state.unAdWlActId.includes(actId)) return;
    if (unAdWlChannel.includes(channel)) {
      Toast({ message: '不符合办理条件，您无法订购', className: 'custom-toast' });
      return;
    }

    Toast({ message: '亲，正在为您推荐合适产品~', className: 'custom-toast' });

    // if (process.env.NODE_ENV == 'development') return;

    if (actId !== 1200 && !fromWl && step !== 'back') {
      dispatch('selfWl', { params, step });
      return;
    }

    if (process.env.NODE_ENV == 'development') return;

    if (step == 'back') {
      // 所有活动的用户退出都给发财树12-10
      // 发财树
      dispatch('recsdkWl', { params, step });
    } else if (llg.actIds.includes(actId)) {
      // 流量果
      dispatch('llgWl', { params, step });
    } else if (llb.actIds.includes(actId)) {
      // 流量宝
      dispatch('llbWl', { params, step });
    } else {
      // 发财树
      dispatch('recsdkWl', { params, step });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
