/* eslint-disable no-undef */
import { setCookie } from '@/utils/cookies';
import Router from '@/router';
import { dotPREORDER, dotORDER, dotERROR, dotSUBMIT, dotCHANGE } from '@/api/dot';
import { getProvinceApi } from '@/api/tools';
import { orderApi, payApi, newPreOrderApi } from '@/api';
import { Toast } from 'vant';

const getDefaultState = () => {
  return {
    // 下单
    params: {}, // 预下单参数
    preOrderData: null, // 预下单返回数据
    currentPreOrderData: null, // 循环下单时切换当前下单产品的预下单信息
    orderData: null, // 下单返回数据====captchaLength（验证码位数）
    payParams: null,
    payData: null, // 支付返回数据
    wlData: null, // 自有尾流信息

    province: '',
    city: '',
    op: '',

    // 隐私协议
    checked: false, // 隐私协议勾选
    policy: false, // 隐私协议弹窗
    policyWoread: false, // 沃悦读隐私协议弹窗

    // cap
    capFlag: 0, // cap类型（0: 默认 1: cap集成 2: 伪cap）
    fsrc: '', // cap集成链接
    hasValidate: false, // cap页面是否有验证码
    isCover: false, // cap页面顶部是否遮挡

    // 验证码
    isSendCode: false, // 验证码输入框（破解弹窗）
    codeContent: '获取验证码',
    codeTimer: null,
    codeTime: 60,

    // 页面操作
    canPreOrder: true, // 能否预下单
    canOrder: true, // 能否下单
    canPay: true, // 能否支付
    canSend: true, // 能否发送验证码
    canPayByReturn: true,
    hasPayReq: false, // 是否已提交验证码
    hasPayReturn: false, // 提交验证码接口是否已返回内容

    unAutoOrderChannel: ['tx'],
    unAutoOrderActIds: [746, 747, 801, 904, 1014, 1028, 1029, 1058, 1066, 1067, 1074, 1088, 1122],
    unAutoOrderProductIds: [], //380, 398, 397, 392, 388, 386, 399, 393, 381, 447
    unAutoOrderOp: ['中国联通'],
    unAutoOrderProvince: ['辽宁', '新疆', '内蒙古', '云南', '湖南'],

    unAutoCheckProductIds: [380, 398, 397, 392, 388],
    unAutoCheckChannel: ['tx'],
    unAutoCheckOp: ['中国联通'],
    unAutoCheckProvince: ['辽宁', '新疆', '内蒙古', '云南', '湖南'],

    // 二次确认弹窗
    showPaySubmit: false,
    configPaySubmitInfo: {
      326: [
        {
          actIds: [
            598, 673, 680, 738, 742, 746, 747, 762, 774, 786, 819, 822, 919, 955, 998, 999, 1026,
            1028, 1027, 1029, 1014, 1034, 1038, 1086,
          ],
          hasPaySubmit: false,
          dialogInfo: {
            paySubmitBg:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_red_326.png',
            paySubmitCancelBtn: '取消',
            paySubmitSureBtn:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_326.png',
          },
        },
        {
          actIds: [502, 801, 802, 904, 907, 934],
          hasPaySubmit: false,
          dialogInfo: {
            paySubmitBg:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_blue_326.png',
            paySubmitCancelBtn: '取消',
            paySubmitSureBtn:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_326.png',
          },
        },
      ],
      355: [
        {
          actIds: -1,
          channel: ['csj', 'ks', 'wl'],
          hasPaySubmit: false,
          dialogInfo: {
            paySubmitBg:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_yellow_355.png',
            paySubmitCancelBtn: '取消',
            paySubmitSureBtn:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_355.png',
            style: {
              sureTextStyle: {},
              sureImgStyle: {
                width: '3.4rem',
              },
              cancelTextStyle: {
                fontSize: '0.4rem',
                color: '#d0d0d0',
              },
              cancelImgStyle: {},
              bottom: '1rem',
            },
          },
        },
      ],
      415: [
        {
          actIds: -1,
          channel: ['csj', 'ks', 'wl'],
          hasPaySubmit: false,
          dialogInfo: {
            paySubmitBg:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_yellow_355.png',
            paySubmitCancelBtn: '取消',
            paySubmitSureBtn:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_355.png',
            style: {
              sureTextStyle: {},
              sureImgStyle: {
                width: '3.4rem',
              },
              cancelTextStyle: {
                fontSize: '0.4rem',
                color: '#d0d0d0',
              },
              cancelImgStyle: {},
              bottom: '1rem',
            },
          },
        },
      ],
      332: [
        {
          actIds: -1,
          channel: ['csj', 'ks', 'wl'],
          hasPaySubmit: false,
          dialogInfo: {
            paySubmitBg:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_yellow_355.png',
            paySubmitCancelBtn: '取消',
            paySubmitSureBtn:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_355.png',
            style: {
              sureTextStyle: {},
              sureImgStyle: {
                width: '3.4rem',
              },
              cancelTextStyle: {
                fontSize: '0.4rem',
                color: '#d0d0d0',
              },
              cancelImgStyle: {},
              bottom: '1rem',
            },
          },
        },
      ],
      393: [
        {
          actIds: [1247],
          hasPaySubmit: false,
          dialogInfo: {
            paySubmitBg:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_yellow_355.png',
            paySubmitCancelBtn: '取消',
            paySubmitSureBtn:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_355.png',
            style: {
              sureTextStyle: {},
              sureImgStyle: {
                width: '3.4rem',
              },
              cancelTextStyle: {
                fontSize: '0.4rem',
                color: '#d0d0d0',
              },
              cancelImgStyle: {},
              bottom: '1rem',
            },
          },
        },
      ],
      396: [
        {
          actIds: [1247],
          hasPaySubmit: false,
          dialogInfo: {
            paySubmitBg:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_yellow_355.png',
            paySubmitCancelBtn: '取消',
            paySubmitSureBtn:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_355.png',
            style: {
              sureTextStyle: {},
              sureImgStyle: {
                width: '3.4rem',
              },
              cancelTextStyle: {
                fontSize: '0.4rem',
                color: '#d0d0d0',
              },
              cancelImgStyle: {},
              bottom: '1rem',
            },
          },
        },
      ],
      399: [
        {
          actIds: [1247],
          hasPaySubmit: false,
          dialogInfo: {
            paySubmitBg:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_yellow_355.png',
            paySubmitCancelBtn: '取消',
            paySubmitSureBtn:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_355.png',
            style: {
              sureTextStyle: {},
              sureImgStyle: {
                width: '3.4rem',
              },
              cancelTextStyle: {
                fontSize: '0.4rem',
                color: '#d0d0d0',
              },
              cancelImgStyle: {},
              bottom: '1rem',
            },
          },
        },
      ],
      381: [
        {
          actIds: [1247],
          hasPaySubmit: false,
          dialogInfo: {
            paySubmitBg:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_yellow_355.png',
            paySubmitCancelBtn: '取消',
            paySubmitSureBtn:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_355.png',
            style: {
              sureTextStyle: {},
              sureImgStyle: {
                width: '3.4rem',
              },
              cancelTextStyle: {
                fontSize: '0.4rem',
                color: '#d0d0d0',
              },
              cancelImgStyle: {},
              bottom: '1rem',
            },
          },
        },
      ],
      386: [
        {
          actIds: [1247],
          hasPaySubmit: false,
          dialogInfo: {
            paySubmitBg:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_yellow_355.png',
            paySubmitCancelBtn: '取消',
            paySubmitSureBtn:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_355.png',
            style: {
              sureTextStyle: {},
              sureImgStyle: {
                width: '3.4rem',
              },
              cancelTextStyle: {
                fontSize: '0.4rem',
                color: '#d0d0d0',
              },
              cancelImgStyle: {},
              bottom: '1rem',
            },
          },
        },
      ],
      392: [
        {
          actIds: -1,
          hasPaySubmit: false,
          dialogInfo: {
            paySubmitBg:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_yellow_355.png',
            paySubmitCancelBtn: '取消',
            paySubmitSureBtn:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/pay_submit_355.png',
            style: {
              sureTextStyle: {},
              sureImgStyle: {
                width: '3.4rem',
              },
              cancelTextStyle: {
                fontSize: '0.4rem',
                color: '#d0d0d0',
              },
              cancelImgStyle: {},
              bottom: '1rem',
            },
          },
        },
      ],
      322: [
        {
          actIds: -1,
          hasPaySubmit: false,
          dialogInfo: {
            paySubmitBg:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/actbb2/static/img/734/pay_submit_734.png',
            paySubmitCancelBtn: '取消',
            paySubmitSureBtn: '确认',
            hasPayContent: true,
            submitBtnBg:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/actbb2/static/img/734/pay_btn_bg_734.png',
            cancelBtnBg:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/actbb2/static/img/734/pay_btn_bg_734.png',
            style: {
              sureTextStyle: {
                width: '2.36rem',
                height: '0.68rem',
                fontSize: '0.3rem',
                color: '#ffffff',
              },
              sureImgStyle: {},
              cancelTextStyle: {
                width: '2.36rem',
                height: '0.68rem',
                fontSize: '0.3rem',
                color: '#ffffff',
              },
              cancelImgStyle: {},
              bottom: '0.5rem',
            },
          },
        },
      ],
      256: [
        {
          actIds: -1,
          hasPaySubmit: false,
          dialogInfo: {
            paySubmitBg:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/actbb2/static/img/734/pay_submit_734.png',
            paySubmitCancelBtn: '取消',
            paySubmitSureBtn: '确认',
            hasPayContent: true,
            submitBtnBg:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/actbb2/static/img/734/pay_btn_bg_734.png',
            cancelBtnBg:
              'https://sgact.oss-cn-hangzhou.aliyuncs.com/actbb2/static/img/734/pay_btn_bg_734.png',
            style: {
              sureTextStyle: {
                width: '2.36rem',
                height: '0.68rem',
                fontSize: '0.3rem',
                color: '#ffffff',
              },
              sureImgStyle: {},
              cancelTextStyle: {
                width: '2.36rem',
                height: '0.68rem',
                fontSize: '0.3rem',
                color: '#ffffff',
              },
              cancelImgStyle: {},
              bottom: '0.5rem',
            },
          },
        },
      ],
    },

    newOrderProcessAct: [200, 1121, 1147, 1057, 1152, 1156],
    newPreOrderDataList: null,
    maxOrderNum: 3,
    orderNum: 1,
    failOrderProductIdList: [],
  };
};

const state = getDefaultState();

const getters = {
  isUnAutoOrder: (state) => {
    // const { curProdJson } = rootGetters;
    // const { formInfo } = curProdJson || {};
    // const { checkType, orderStep } = formInfo || {};

    const {
      params,
      preOrderData,
      unAutoOrderActIds,
      unAutoOrderChannel,
      unAutoOrderProductIds,
      unAutoOrderOp,
      unAutoOrderProvince,
    } = state;
    const { actId, channel } = params || {};
    const { productId, province, op } = preOrderData || {};
    console.log(preOrderData);
    console.log(productId, actId, channel);

    let flag = true;

    if (actId == 1229) {
      flag =
        unAutoOrderActIds.includes(actId) ||
        unAutoOrderChannel.includes(channel) ||
        unAutoOrderProductIds.includes(productId) ||
        ['安徽', '内蒙古'].includes(province) ||
        (unAutoCheckOp.includes(op) && ['湖南'].includes(province));
      console.log(flag);
    } else {
      flag =
        unAutoOrderActIds.includes(actId) ||
        unAutoOrderChannel.includes(channel) ||
        unAutoOrderProductIds.includes(productId) ||
        (unAutoOrderOp.includes(op) && unAutoOrderProvince.includes(province));
    }

    return flag;
  },
  isUnAutoCheck: (state, getters, rootState, rootGetters) => {
    const { curProdJson } = rootGetters;
    const { formInfo } = curProdJson || {};
    const { checkType } = formInfo || {};

    const {
      params,
      preOrderData,
      unAutoCheckProductIds,
      unAutoCheckChannel,
      unAutoCheckOp,
      unAutoCheckProvince,
    } = state;
    const { channel, actId } = params || {};
    const { productId, province, op } = preOrderData || {};

    let flag = true;

    if (actId == 1229) {
      flag =
        unAutoCheckProductIds.includes(productId) ||
        unAutoCheckChannel.includes(channel) ||
        ['安徽', '内蒙古'].includes(province) ||
        (unAutoCheckOp.includes(op) && ['湖南'].includes(province)) ||
        checkType;
      console.log(flag);
    } else {
      flag =
        unAutoCheckProductIds.includes(productId) ||
        unAutoCheckChannel.includes(channel) ||
        (unAutoCheckOp.includes(op) && unAutoCheckProvince.includes(province)) ||
        checkType;
    }

    return flag;
  },
  currentPaySubmitInfo: (state) => {
    const { configPaySubmitInfo, preOrderData, params } = state;
    const { actId = 502, channel } = params || {};
    const { productId = 326 } = preOrderData || {};
    const currentProd = configPaySubmitInfo[productId] || [];

    let findPaySubmit =
      currentProd.find(
        (item) =>
          item.actIds == -1 ||
          item.actIds.includes(actId) ||
          (item.channel && item.channel.includes(channel)),
      ) || {};
    // console.log(findPaySubmit.channel && !findPaySubmit.channel.includes(channel));
    if (findPaySubmit.channel && !findPaySubmit.channel.includes(channel)) {
      findPaySubmit = {};
    }
    const paySubmitDialog = findPaySubmit.dialogInfo || {};

    /* const currentInfo = currentProd && currentProd.find((item) => item.actIds == -1 || item.actIds.includes(actId))?.dialogInfo;
		console.log(currentInfo); */
    return findPaySubmit.hasPaySubmit && paySubmitDialog;
  },
};

const mutations = {
  SET_PARAMS: (state, params) => {
    state.params = params;
  },
  SET_PREORDERDATA: (state, preOrderData) => {
    state.preOrderData = preOrderData;
    setCookie('preOrderData', JSON.stringify(preOrderData));
  },
  SET_ORDERDATA: (state, orderData) => {
    state.orderData = orderData;
  },
  SET_PAYPARAMS: (state, payParams) => {
    state.payParams = payParams;
  },
  SET_PAYDATA: (state, payData) => {
    state.payData = payData;
  },
  SET_WLDATA: (state, wlData) => {
    state.wlData = wlData;
  },
  SET_PROVINCE: (state, province) => {
    state.province = province;
  },
  SET_CITY: (state, city) => {
    state.city = city;
  },
  SET_OP: (state, op) => {
    state.op = op;
  },
  SET_CHECKED: (state, checked) => {
    state.checked = checked;
  },
  SET_POLICY: (state, policy) => {
    state.policy = policy;
  },
  SET_POLICYWOREAD: (state, policyWoread) => {
    state.policyWoread = policyWoread;
  },
  SET_IS_SENDCODE: (state, isSendCode) => {
    state.isSendCode = isSendCode;
  },
  SET_CAPFLAG: (state, capFlag) => {
    state.capFlag = capFlag;
  },
  SET_FSRC: (state, fsrc) => {
    state.fsrc = fsrc;
  },
  SET_HASVALIDATE: (state, hasValidate) => {
    state.hasValidate = hasValidate;
  },
  SET_ISCOVER: (state, isCover) => {
    state.isCover = isCover;
  },
  SET_CONTENT: (state, codeContent) => {
    state.codeContent = codeContent;
  },
  SET_CODETIMER: (state, codeTimer) => {
    state.codeTimer = codeTimer;
  },
  SET_CODETIME: (state, codeTime) => {
    state.codeTime = codeTime;
  },
  SET_CANPREORDER: (state, canPreOrder) => {
    state.canPreOrder = canPreOrder;
  },
  SET_CANORDER: (state, canOrder) => {
    state.canOrder = canOrder;
  },
  SET_CANPAY: (state, canPay) => {
    state.canPay = canPay;
  },
  SET_CANSEND: (state, canSend) => {
    state.canSend = canSend;
  },
  SET_CANPAYBYRETURN: (state, canPayByReturn) => {
    state.canPayByReturn = canPayByReturn;
  },
  SET_HASPAYRETURN: (state, hasPayReturn) => {
    state.hasPayReturn = hasPayReturn;
  },
  SET_HASPAYREQ: (state, hasPayReq) => {
    state.hasPayReq = hasPayReq;
  },
  SET_SHOWPAYSUBMIT: (state, showPaySubmit) => {
    state.showPaySubmit = showPaySubmit;
  },
  SET_NEWPREORDERDATALIST: (state, newPreOrderDataList) => {
    state.newPreOrderDataList = newPreOrderDataList;
  },
  SET_ORDERNUM: (state, orderNum) => {
    state.orderNum = orderNum;
  },
  SET_FAILORDERPRODUCTIDLIST: (state, failOrderProductId) => {
    state.failOrderProductIdList.push(failOrderProductId);
  },
};

const actions = {
  async geteProvince({ commit }, mobile) {
    const res = await getProvinceApi(mobile);
    if (res.code == 0) {
      const { province, city, sp } = res.data || {};
      commit('SET_PROVINCE', province);
      commit('SET_CITY', city);
      commit('SET_OP', sp);
    }
  },

  preOrderHandle({ commit, state, dispatch }, params) {
    const mobileReg = /^1(3|4|5|6|7|8|9)\d{9}$/;

    if (!params.mobile) {
      Toast('请输入手机号码');
      return;
    }
    if (!mobileReg.test(params.mobile)) {
      Toast('手机号码输入错误');
      return;
    }

    if (!state.canPreOrder) {
      Toast('请稍后再试！');
      return;
    }
    commit('SET_CANPREORDER', false);

    let timer = setTimeout(() => {
      commit('SET_CANPREORDER', true);
      clearTimeout(timer);
    }, 5000);
    dispatch('preOrder', params);
  },

  async preOrder({ commit, state, getters, dispatch }, params) {
    setCookie('mobile', params.mobile);

    try {
      // Toast.loading({ duration: 0 });
      dispatch('geteProvince', params.mobile);

      try {
        dotPREORDER({
          act: params.actId,
          actionIntro: `预下单事件`,
          queryKey: 'mobile',
          queryValue: params.mobile,
          reqParams: JSON.stringify(params),
        });
      } catch (error) {
        console.log(error);
      }

      let res;

      res = await newPreOrderApi(params);

      try {
        dotPREORDER({
          act: params.actId,
          actionIntro: `预下单完成`,
          queryKey: 'mobile',
          queryValue: params.mobile,
          reqParams: JSON.stringify(params),
          respParams: JSON.stringify({
            code: res.code,
            msg: res.msg,
            data: res.data,
          }),
        });
      } catch (error) {
        console.log(error);
      }

      if (Array.isArray(res.data)) {
        commit('SET_NEWPREORDERDATALIST', res.data);
        commit('SET_PREORDERDATA', res.data[0]);
      } else {
        commit('SET_PREORDERDATA', res.data);
      }

      commit('SET_PARAMS', params);

      dispatch('gdyd/track', { processType_var: '输⼊电话号码' }, { root: true });

      // Toast.clear();

      // console.log(res);

      if (res.code == 1) {
        // 勾选隐私协议
        if (!getters.isUnAutoCheck) commit('SET_CHECKED', true);

        if (state.preOrderData.configKey == 'CRACK_PAGE') {
          // 下发验证码
          // 手动下发
          if (getters.isUnAutoOrder) {
            commit('SET_IS_SENDCODE', true);

            // 设置当前手势引导步骤===点击获取验证码
            dispatch('config/setCurrentStep', { currentStep: 'CLICK_CODE' }, { root: true });
          }
          // order
          // 自动下发
          else {
            console.log('自动下发验证码');

            dispatch('order');
          }
        } else if (
          state.preOrderData.configKey == 'UN_INTEGRATE_PAGE' ||
          state.preOrderData.configKey == 'UN_SKIP_PAGE'
        ) {
          // 伪cap集成
          setTimeout(() => {
            commit('SET_CAPFLAG', 2);
            clearTimeout();
          }, 1000);
        } else {
          // cap
          // order
          dispatch('order');
        }
      }
      // 尾流
      else if (res.code == 40006) {
        // Toast({ message: res.msg, className: "custom-toast2" });
        commit('SET_WLDATA', res.data);
        dispatch('ad/adWl', { step: 'preOrder' }, { root: true });
      } else {
        // Toast({ message: res.msg, className: "custom-toast2" });
      }
      return res;
    } catch (error) {
      console.log(error);
    }
  },

  orderHandle({ dispatch }) {
    if (!state.preOrderData) {
      dispatch('preOrder', state.params);
      return;
    }

    if (!state.canOrder) {
      Toast({ message: '请不要频繁点击', className: 'custom-toast2' });
      return;
    }
  },

  async order({ commit, state, dispatch }) {
    const { newPreOrderDataList, maxOrderNum } = state;

    let newPreOrderData;
    if (Array.isArray(newPreOrderDataList)) {
      const notOrderProduct = newPreOrderDataList.find(
        (item) => !state.failOrderProductIdList.includes(item.productId),
      );
      // newPreOrderData = newPreOrderDataList[state.orderNum];
      if (!notOrderProduct) {
        commit('SET_CANORDER', true);
        dispatch('ad/adWl', { step: 'order' }, { root: true });
        return;
      }

      newPreOrderData = notOrderProduct;
      try {
        console.log('change事件');
        dotCHANGE({
          act: state.params.actId,
          origin: newPreOrderData.ascription,
          productName: newPreOrderData.productName,
          actionIntro: '切换产品事件',
          queryKey: 'mobile',
          queryValue: state.params.mobile,
          queryKeyNd: 'productId',
          queryValueNd: newPreOrderData.productId,
          reqParams: JSON.stringify({ orderNo: newPreOrderData.orderNo }),
        });
      } catch (error) {
        console.log(error);
      }
    }

    const { orderNo, productName, productId, ascription, configKey } =
      newPreOrderData || state.preOrderData;
    let returnUrl;
    if (configKey.includes('CAP')) {
      localStorage.setItem(
        'successUrl',
        window.location.href.split('index')[0] + 'success?productId=' + productId,
      );
      returnUrl = window.location.origin + '/wait?productId=' + productId;
    } else {
      returnUrl = window.location.href.split('index')[0] + 'success?productId=' + productId;
    }
    console.log('下单订单号：', orderNo);
    console.log('下单产品ID：', productId);

    try {
      // Toast.loading({ duration: 0 });

      let res;

      // 打点
      try {
        dotORDER({
          act: state.params.actId,
          origin: ascription,
          productName: productName,
          actionIntro: '下发验证码事件',
          queryKey: 'mobile',
          queryKeyNd: 'productId',
          queryValueNd: productId,
          queryValue: state.params.mobile,
          reqParams: JSON.stringify({ orderNo }),
        });
      } catch (error) {
        console.log(error);
      }

      // 湖南移动插码===获取验证码
      dispatch('hunan_cmcc/track_GETCODE', {}, { root: true });

      // 下单
      try {
        res = await orderApi({ orderNo, returnUrl, realmName: window.location.host });
      } catch (error) {
        dotERROR({
          act: state.params.actId,
          origin: ascription,
          productName: productName,
          actionIntro: '下发验证码接口异常',
          queryKey: 'mobile',
          queryValue: state.params.mobile,
          reqParams: JSON.stringify(state.params),
          respParams: 'ERROR：' + error,
        });
      }

      // 打点
      try {
        dotORDER({
          act: state.params.actId,
          origin: ascription,
          productName: productName,
          actionIntro: `下发验证码===${res.msg}`,
          queryKey: 'mobile',
          queryValue: state.params.mobile,
          queryKeyNd: 'productId',
          queryValueNd: productId,
          reqParams: JSON.stringify({ orderNo }),
          respParams: JSON.stringify({
            code: res.code,
            msg: res.msg,
            data: res.data,
          }),
        });
      } catch (error) {
        console.log(error);
      }
      // Toast.clear();

      commit('SET_ORDERNUM', state.orderNum + 1);
      commit('SET_ORDERDATA', res.data);

      // 平台打点
      try {
        if (state.params.channel.includes('tuia')) {
          countLog.ready(function () {
            countLog.init(
              function () {
                // 如果需要跳转，在此处写入 window.location.href = 跳转链接
              },
              {
                // 是否需要复制订单号，是为 1，不是为 0
                isCopy: 0,
                // 上报转化类型，具体见下表
                pageType: 0,
              },
            );
          });
        }
      } catch (error) {
        console.log(error);
      }

      if (res.code != 1) {
        // 湖南移动插码===获取验证码失败
        dispatch('hunan_cmcc/track_GETCODE_FAILE', {}, { root: true });

        commit('SET_FAILORDERPRODUCTIDLIST', productId);
        if (
          state.orderNum <= maxOrderNum &&
          Array.isArray(newPreOrderDataList) &&
          newPreOrderDataList[state.orderNum]
        ) {
          dispatch('order');
          return;
        }
      }

      commit('SET_CANSEND', true);

      if (res.code == 1) {
        if (newPreOrderData) commit('SET_PREORDERDATA', newPreOrderData);

        // 设置当前手势引导步骤===输入验证码
        dispatch('config/setCurrentStep', { currentStep: 'INPUT_CODE' }, { root: true });

        // 湖南移动插码===获取验证码成功
        dispatch('hunan_cmcc/track_GETCODE_SUCCESS', {}, { root: true });
        // 广东移动打点
        dispatch(
          'gdyd/track',
          {
            apiId_var: 'SendOrderConfirmSms',
            apiName_var: '商品办理确认短信下发接口',
            processType_var: '获取短信验证码',
          },
          { root: true },
        );

        if (state.preOrderData.configKey.includes('CAP')) {
          commit('SET_CAPFLAG', 1);
          commit('SET_FSRC', res.data.payUrl);
          if (state.preOrderData.configKey == 'CAP_A_COVER') {
            // this.isCover = true;
            commit('SET_ISCOVER', true);
          }
          if (state.preOrderData.configKey == 'CAP_B') {
            commit('SET_HASVALIDATE', true);
            // this.hasValidate = true;
          }
          if (state.preOrderData.configKey == 'CAP_B_COVER') {
            commit('SET_HASVALIDATE', true);
            commit('SET_ISCOVER', true);
            // this.hasValidate = true;
            // this.isCover = true;
          }
        } else if (state.preOrderData.configKey == 'CRACK_PAGE') {
          commit('SET_IS_SENDCODE', true);
          // 倒计时
          dispatch('codeTime');
          Toast({
            message: '验证码已发送，请注意查收',
            className: 'custom-toast',
          });
        } else {
          window.location.href = res.data.payUrl;
        }
      } else if (res.code == 40006) {
        // Toast({ message: res.msg, className: "custom-toast2" });
        commit('SET_WLDATA', res.data);
        commit('SET_CANORDER', true);
        dispatch('ad/adWl', { step: 'order' }, { root: true });
      } else {
        console.log(res.msg);
        Toast({ message: res.msg, className: 'custom-toast2' });
        commit('SET_CANORDER', true);
      }
      return res;
    } catch (error) {
      console.log(error);
    }
  },

  async pay({ commit, state, dispatch, rootGetters }) {
    const { productName, ascription, productId } = state.preOrderData;
    const { currentRoute, params } = rootGetters;
    const { actId } = params || {};

    // 湖南移动插码===输入验证码
    dispatch('hunan_cmcc/track_INPUTCODE', {}, { root: true });
    // 湖南移动插码===确认办理
    dispatch('hunan_cmcc/track_QRBL', {}, { root: true });
    // 湖南移动插码===业务规则校验
    dispatch('hunan_cmcc/track_BUSINESSRULE_VALID', {}, { root: true });
    // 广东移动插码
    dispatch('gdyd/track', { processType_var: '输⼊短信验证码' }, { root: true });
    dispatch(
      'gdyd/track',
      {
        apiId_var: 'productorder_ckcommid',
        apiName_var: '商品办理(过滤黑名单)',
        processType_var: '立即办理',
      },
      { root: true },
    );
    dispatch(
      'gdyd/track',
      {
        apiId_var: 'productorder_ckcommid',
        apiName_var: '商品办理(过滤黑名单)',
        processType_var: '办理成功',
      },
      { root: true },
    );

    // req && commit("SET_PAYPARAMS", { orderNo: state.orderData.orderNo, code: req.code });

    // console.log(state.payParams);

    Toast.loading({ duration: 0 });

    // 打点
    try {
      dotSUBMIT({
        act: actId,
        origin: ascription,
        productName: productName,
        url: 'act2.ilives.com.cn',
        path: '/order/pay',
        actionIntro: `提交验证码事件`,
        queryKey: 'mobile',
        queryValue: state.params.mobile || state.orderData.orderNo,
        queryKeyNd: 'productId',
        queryValueNd: productId,
        reqParams: JSON.stringify({
          orderNo: state.orderData.orderNo,
          code: state.payParams.code,
        }),
      });
    } catch (error) {
      console.log(error);
    }

    commit('SET_CANPAYBYRETURN', false);

    payApi(state.payParams)
      .then((res) => {
        commit('SET_CANPAYBYRETURN', true);
        // 打点
        try {
          dotSUBMIT({
            act: actId,
            origin: ascription,
            productName: productName,
            url: 'act2.ilives.com.cn',
            path: '/order/pay',
            actionIntro: `提交验证码===${res.msg}`,
            queryKey: 'mobile',
            queryValue: state.params.mobile || state.orderData.orderNo,
            queryKeyNd: 'productId',
            queryValueNd: productId,
            reqParams: JSON.stringify({
              orderNo: state.orderData.orderNo,
              code: state.payParams.code,
            }),
            respParams: JSON.stringify(res),
          });
        } catch (error) {
          console.log(error);
        }

        // 清除提交验证码参数缓存
        commit('SET_PAYPARAMS', null);

        sessionStorage.setItem('preOrderData', JSON.stringify(state.preOrderData));
        if (res.code == 1) {
          // 湖南移动插码===业务规则校验成功
          dispatch('hunan_cmcc/track_BUSINESSRULE_SUCCESS', {}, { root: true });
          // 湖南移动插码===办理成功
          dispatch('hunan_cmcc/track_ORDER_SUCCESS', {}, { root: true });
          // 广东移动打点
          dispatch(
            'gdyd/track',
            {
              apiId_var: 'productorder_ckcommid',
              apiName_var: '商品办理(过滤黑名单)',
              processType_var: '办理成功',
            },
            { root: true },
          );

          if ([508, 509, 510].includes(productId)) {
            try {
              // 科大讯飞外接产品跳转指定页面
              if (res.data.extra) {
                window.location.href = res.data.extra;
              }
            } catch (error) {
              console.log(error);
            }
          } else {
            // 跳转成功页
            Router.push({
              path: currentRoute.path.replace('index', 'success'),
            });
          }

          commit('SET_IS_SENDCODE', false);
          commit('SET_SHOWPAYSUBMI', false);
          dispatch('config/setCurrentStep', { currentStep: 'INPUT_MOBILE' }, { root: true });
        } else {
          // 湖南移动插码===业务规则校验成功
          dispatch('hunan_cmcc/track_BUSINESSRULE_FAILE', {}, { root: true });
          // 湖南移动插码===办理失败
          dispatch('hunan_cmcc/track_ORDER_FAILE', { message: res.msg }, { root: true });
          // 广东移动插码
          dispatch(
            'gdyd/track',
            {
              apiId_var: 'productorder_ckcommid',
              apiName_var: '商品办理(过滤黑名单)',
              processType_var: '办理失败',
              errorMessage_var: res.msg,
            },
            { root: true },
          );

          Toast({ message: res.msg, className: 'custom-toast2' });
        }
      })
      .catch((e) => {
        console.log(e);
        commit('SET_CANPAYBYRETURN', true);
        Toast.clear();

        // 打点
        try {
          dotSUBMIT({
            act: actId,
            origin: ascription,
            productName: productName,
            url: 'act2.ilives.com.cn',
            path: '/order/pay',
            actionIntro: `提交验证码接口异常`,
            queryKey: 'mobile',
            queryValue: state.params.mobile || state.orderData.orderNo,
            reqParams: JSON.stringify({
              orderNo: state.orderData.orderNo,
              code: state.payParams.code,
            }),
            respParams: 'ERROR：' + e,
          });
        } catch (error) {
          console.log(error);
        }
      });
  },

  closePayDia({ commit }) {
    commit('SET_CODETIMER', null);
    commit('SET_CONTENT', '获取验证码');
    commit('SET_CODETIME', 60);
    commit('SET_CANSEND', true);
    commit('SET_IS_SENDCODE', false);
  },

  codeTime({ commit, state }) {
    commit('SET_CONTENT', state.codeTime + 's');
    commit('SET_CANSEND', false);

    let timer = window.setInterval(() => {
      commit('SET_CODETIME', state.codeTime - 1);
      commit('SET_CONTENT', state.codeTime + 's');
      if (state.codeTime < 0) {
        //当倒计时小于0时清除定时器
        window.clearInterval(timer);
        commit('SET_CONTENT', '获取验证码');
        // commit('SET_CONTENT', '重新获取');
        commit('SET_CODETIME', 60);
        commit('SET_CANSEND', true);
      }
    }, 1000);
  },

  sendCode({ state, commit, dispatch }) {
    const { canSend } = state;
    if (!canSend) {
      Toast('请不要频繁点击！');
      return;
    }
    commit('SET_CANSEND', false);
    // Toast.loading();
    // dispatch("codeTime", vm);
    dispatch('order');
  },

  // 是否已发送
  btnHandle({ commit, state, getters, dispatch }, data) {
    console.log(data);

    // router.push()
    const { orderData } = state;
    const { captchaLength } = orderData || {};
    const { currentPaySubmitInfo } = getters;
    return new Promise((resolve) => {
      const { code } = data;
      console.log(code);
      const { params, isSendCode, checked, canPay, canPayByReturn } = state;
      // const { productId } = preOrderData || {};
      if (!isSendCode) {
        dispatch('preOrder', params).then((res) => {
          resolve(res);
        });
        return;
      }

      if (!checked) {
        Toast({
          message: '请阅读并同意下方相关隐私条款！',
          className: 'custom-toast2',
        });
        return;
      }
      if (!code) {
        Toast({ message: '请输入验证码', className: 'custom-toast2' });
        return;
      }
      if (captchaLength && code.toString().length !== captchaLength) {
        Toast({ message: '验证码位数错误', className: 'custom-toast2' });
        return;
      }
      if (!canPay || !canPayByReturn) {
        Toast('请不要频繁点击！');
        return;
      }
      commit('SET_CANPAY', false);
      setTimeout(() => {
        commit('SET_CANPAY', true);
        window.clearTimeout();
      }, 5000);

      // 湖南移动插码===立即办理
      dispatch('hunan_cmcc/track_LJBL', {}, { root: true });

      // 缓存提交验证码参数
      commit('SET_PAYPARAMS', { orderNo: state.orderData.orderNo, code });

      if (currentPaySubmitInfo) {
        commit('SET_SHOWPAYSUBMIT', true);
        return;
      }
      // console.log(code);
      dispatch('pay');
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
